<template>
  <v-dialog
    persistent
    v-model="dialogEditProfileActive"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold mt-2">{{ texts.profile.editForm.textTitle }}</p>
      <div class="mt-9">
        <v-text-field
          v-model="name"
          type="text"
          :label="texts.profile.editForm.textName"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.profile.editForm.textName"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          v-model="lastName"
          type="text"
          :label="texts.profile.editForm.textLastName"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.profile.editForm.textLastName"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          v-model="email"
          type="text"
          :label="texts.profile.editForm.textEmail"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.profile.editForm.textEmail"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          v-model="position"
          type="text"
          :label="texts.profile.editForm.textPosition"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.profile.editForm.textPosition"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          v-model="phone"
          type="text"
          :label="texts.profile.editForm.textPhone"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.profile.editForm.textPhone"
          persistent-placeholder
          @keypress="soloNumbers"
        ></v-text-field>
      </div>
      <div
        v-if="isResponsive"
        class="mt-2"
      >
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-primary mon-bold"
          style="width: 100%;"
        >
          {{ texts.profile.editForm.textButtonSaveChanges }}
        </v-btn>
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-secondary mon-regular mt-5 mb-2"
          style="width: 100%;"
        >
          {{ texts.profile.editForm.textButtonClose }}
        </v-btn>
      </div>
      <div
        v-else
        class="display-flex align-items-center mt-2"
      >
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-cancel mon-regular"
        >
          {{ texts.profile.editForm.textButtonClose }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-save mon-bold"
        >
          {{ texts.profile.editForm.textButtonSaveChanges }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ProfileDialogEditProfileLayout",
  data() {
    //VARIABLES
    return {
      bLoading: false,
      texts: "",
      screenWidth: 0,
      name: "",
      lastName: "",
      email: "",
      position: "",
      phone: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    this.texts = FILE.profile[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    saveChanges: function () {
      this.bLoading = true;

      const payload = {
        sName: this.name,
        sLastname: this.lastName,
        sEmail: this.email,
        sPositionName: this.position,
        sPhoneNumber: this.phone,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/employees/${this.$store.state.sUserId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.$store.commit("setFullName", this.name + " " + this.lastName);
          this.$store.commit("refresher", true);
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeDialog: function () {
      this.$store.commit("setDialogEditProfile", {
        active: false,
        arr: [],
      });
      this.name = "";
      this.lastName = "";
      this.email = "";
      this.position = "";
      this.phone = "";
    },
  },
  computed: {
    // return create contact dialog state
    dialogEditProfileActive() {
      return this.$store.state.dialogEditProfileActive;
    },
    arr: function () {
      return this.$store.state.dialogEditProfileArr;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.name !== "" &&
        this.lastName !== "" &&
        this.email !== "" &&
        this.position !== "" &&
        this.phone !== ""
      );
    },
  },
  watch: {
    dialogEditProfileActive() {
      if (this.dialogEditProfileActive) {
        this.texts = FILE.profile[this.selectLanguage];
        this.name = this.arr.sName;
        this.lastName = this.arr.sLastname;
        this.email = this.arr.sEmail;
        this.position = this.arr.sPositionName;
        this.phone = this.arr.sPhoneNumber;
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.profile[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.button-save:disabled {
  background: gray !important;
  cursor: not-allowed;
  pointer-events: visible;
}

.button-cancel {
  height: 40px !important;
  width: 45% !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  background-color: #ffffff !important;
  text-transform: initial;
}

.button-save {
  height: 40px !important;
  width: 45% !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}
</style>